import React from 'react';
import { useCart } from '@/context/cart/CartContext';
import Link from 'next/link';
import CartItem from './CartItem';
import { v4 as uuidv4 } from 'uuid';
const CartModal = ({ isOpen, onClose }) => {
  const { cart } = useCart();
  const handleClose = (e) => {
    if (e.target.id === 'container') {
      onClose();
    }
  };
  let cart_products = [];
  if (cart) {
    cart_products = cart.cart_products || [];
  }
  if (!isOpen) null;
  else {
    return (
      <div
        className='relative z-50'
        aria-labelledby='slide-over-title'
        role='dialog'
        aria-modal='true'
      >
        <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'></div>

        <div className='fixed inset-0 overflow-hidden'>
          <div
            id='container'
            onClick={(e) => handleClose(e)}
            className='absolute inset-0 overflow-hidden'
          >
            <div
              className={`pointer-events-none fixed inset-y-0 right-0 flex max-w-full transform pl-10 transition duration-500 ease-in-out sm:duration-700 ${isOpen ? 'translate-x-0' : 'translate-x-full'
                }`}
            >
              <div className='pointer-events-auto w-screen max-w-md'>
                <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl'>
                  <div className='flex-1 overflow-y-auto px-4 py-6 sm:px-6'>
                    <div className='flex items-start justify-between'>
                      <h2
                        className='text-lg font-medium text-gray-900'
                        id='slide-over-title'
                      >
                        Shopping cart
                      </h2>
                      <div
                        onClick={() => onClose()}
                        className='ml-3 flex h-7 items-center'
                      >
                        <button
                          type='button'
                          className='-m-2 p-2 text-gray-400 hover:text-gray-500'
                        >
                          <span className='sr-only'>Close panel</span>
                          <svg
                            className='h-6 w-6'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth='1.5'
                            stroke='currentColor'
                            aria-hidden='true'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M6 18L18 6M6 6l12 12'
                            />
                          </svg>
                        </button>
                      </div>
                    </div>

                    <div className='mt-8'>
                      <div className='flow-root'>
                        <ul
                          role='list'
                          className='-my-6 space-y-2 divide-gray-200'
                        >
                          {cart_products.length !== 0 ? (
                            cart_products.map((item) => (
                              <div
                                key={uuidv4()}
                                className='border-b pb-2'
                              >
                                <div className='flex items-center space-x-2 mb-1'>
                                  <div className='rounded-full border p-1'>
                                    <img className='w-7 h-7 ' src={item?.brand_logo_url} alt={item?.brand_name} />
                                  </div>
                                  <h2>{item?.brand_name}</h2>
                                </div>
                                <div className='flex flex-col space-y-2'>
                                  {item?.products?.map(data => (
                                    <CartItem
                                      key={uuidv4()}
                                      item={data}
                                      onClose={onClose}

                                    />
                                  ))}
                                </div>


                              </div>
                            ))
                          ) : (
                            <div className='mt-24 flex flex-col items-center justify-center'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 512 512'
                                id='trolley'
                                className='mb-4 h-28 w-28'
                              >
                                <g>
                                  <path
                                    fill='none'
                                    d='M237.1 447.3c-6.5 0-11.8 5.3-11.8 11.8s5.3 11.8 11.8 11.8 11.8-5.3 11.8-11.8-5.3-11.8-11.8-11.8zM378.8 447.3c-6.5 0-11.8 5.3-11.8 11.8s5.3 11.8 11.8 11.8c6.5 0 11.8-5.3 11.8-11.8s-5.3-11.8-11.8-11.8z'
                                  ></path>
                                  <path d='M490.6 202c11 0 20-9 20-20s-9-20-20-20h-355c-.6 0-1.3 0-1.9.1l-24.3-63.9c-.1-.2-.1-.3-.2-.5-.1-.4-.3-.7-.5-1.1-.1-.3-.3-.5-.4-.8-.2-.3-.3-.6-.5-.9-.2-.3-.3-.5-.5-.8-.2-.3-.4-.6-.6-.8-.2-.3-.4-.5-.6-.8-.2-.2-.4-.5-.6-.7-.2-.3-.5-.5-.7-.7-.2-.2-.4-.4-.7-.6-.3-.2-.5-.5-.8-.7-.2-.2-.4-.4-.7-.5-.3-.2-.6-.4-.9-.7-.1-.1-.3-.2-.4-.3L33.4 46.4c-9.4-5.8-21.7-2.9-27.5 6.5C.1 62.3 3 74.6 12.4 80.4l62 38.4 87.4 229.9c1.3 9.8 9.7 17.3 19.8 17.3h266c11 0 20-9 20-20s-9-20-20-20H195.9l-16-42h291.6c11 0 20-9 20-20s-9-20-20-20H164.8l-16-42h341.8zM237.1 407.3c-28.6 0-51.8 23.3-51.8 51.8s23.3 51.8 51.8 51.8 51.8-23.3 51.8-51.8-23.2-51.8-51.8-51.8zm0 63.7c-6.5 0-11.8-5.3-11.8-11.8s5.3-11.8 11.8-11.8 11.8 5.3 11.8 11.8-5.3 11.8-11.8 11.8zM378.8 407.3c-28.6 0-51.8 23.3-51.8 51.8s23.3 51.8 51.8 51.8 51.8-23.3 51.8-51.8-23.2-51.8-51.8-51.8zm0 63.7c-6.5 0-11.8-5.3-11.8-11.8s5.3-11.8 11.8-11.8c6.5 0 11.8 5.3 11.8 11.8s-5.3 11.8-11.8 11.8z'></path>
                                </g>
                              </svg>
                              <p className='text-3xl font-bold italic'>Oops!</p>
                              <p className='text-center text-gray-600'>
                                Your cart is currently empty.
                              </p>
                              <p className='mt-4 text-sm text-gray-700'>
                                Elegance defined in our curated collection,
                              </p>
                              <p className='text-sm text-gray-700'>
                                Exclusivity you won't find elsewhere.
                              </p>
                              <a href='/product'>
                                <button
                                  onClick={() => onClose()}
                                  className='mt-2 rounded-sm border border-black bg-white px-4 py-2 text-black shadow-md transition duration-300 ease-in-out hover:bg-black hover:text-white'
                                >
                                  Explore, Try it!
                                </button>
                              </a>
                            </div>
                          )}
                        </ul>
                        {cart_products.length ?
                          (<div className='mt-5 text-black flex w-full justify-between bg-gray-100 px-2 py-1 text-sm'>
                            <Link onClick={() => onClose()} href='/product'>
                              <p>Include more</p>
                            </Link>
                            <Link onClick={() => onClose()} href='/product'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth='1.5'
                                stroke='currentColor'
                                className='h-5 w-5'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  d='M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z'
                                />
                              </svg>
                            </Link>
                          </div>) : null
                        }
                      </div>
                    </div>
                  </div>

                  {/* //buttom portion */}
                  <div className='border-t border-gray-200 px-4 py-6 sm:px-6'>
                    {
                      cart?.sub_total ? (
                        <div className='flex justify-between text-base font-medium text-gray-900'>
                          <p>Subtotal</p>
                          <p>₹ {cart?.sub_total}</p>
                        </div>
                      ) : null
                    }
                    {
                      cart?.sub_total ? (
                        <p className='mt-0.5 text-sm text-gray-500'>
                          Shipping and taxes calculated at checkout.
                        </p>
                      ) : null
                    }

                    <div
                      className={`mt-6 ${cart_products.length === 0 && 'pointer-events-none'
                        }`}
                    >
                      <Link
                        onClick={() => onClose()}
                        href='/checkout'
                        className='flex items-center justify-center rounded-md border border-transparent bg-black px-6 py-3 text-base font-medium text-white shadow-sm'
                      >
                        Checkout
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default CartModal;
